.linebreak {
    white-space: pre-line;
}

.preserve-white-space {
    white-space: pre;
}

.no-wrap {
    white-space: nowrap;
}

@media print {
    .no-print {
        display: none;
    }

    .chakra-toast {
        display: none !important;
    }
}
