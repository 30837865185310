.booking-table {
    border: 1px solid var(--chakra-colors-gray-300);
}

.booking-table  th {
    border: 1px solid var(--chakra-colors-gray-300);
}

.booking-table  td {
    border: 1px solid var(--chakra-colors-gray-300);
}
