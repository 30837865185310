@media print {
    .receipt-container {
      margin: 0 !important;
    }

    input {
        border: 0 !important;
        padding: 0 !important;
    }
  }

input {
    field-sizing: content;
}
